import React from 'react';

import Logo from 'src/components/logo';
import SEO from 'src/components/seo';
import routes from 'src/config/routes';

import SimpleInput from 'src/components/design-system/form/input';
import * as DesignSystem from 'src/components/design-system';
import LoginLayout from 'src/components/layouts/login';

import useForm from 'src/hooks/useForm';

const ResetPage = () => {
  const {
    formData,
    setFormData,
    // isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: { email: '' },
    axiosConfig: {
      method: 'POST',
      url: routes.resetStep1(),
    },
    redirectUrl: `/`,
  });

  return (
    <LoginLayout>
      <SEO title="Mot de passe oublié" />

      <DesignSystem.Card>
        <div className="">
          <Logo className="m-auto h-36" />
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900 leading-9">
            Mot de passe oublié
          </h2>
          <p className="mt-2 text-sm text-gray-600 leading-5 max-w">
            Renseignez l'adresse e-mail principale que vous avez utilisée pour
            la création de votre compte.
          </p>
        </div>

        <div className="">
          <div className="mt-4">
            <form action="#" method="POST" onSubmit={submitForm}>
              <div>
                <label
                  for="email"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Adresse email
                </label>

                <SimpleInput
                  id="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ email: e.target.value })}
                  requestState={requestState}
                />
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                  >
                    Envoyer le lien
                  </button>
                  <DesignSystem.Link
                    to="/login"
                    className="w-full text-center block mt-4 mb-2"
                  >
                    Retour à l'écran de connexion
                  </DesignSystem.Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </DesignSystem.Card>
    </LoginLayout>
  );
};

export default ResetPage;
